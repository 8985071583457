.Typewriter__cursor {
  height: 67px !important;
  width: 6px !important;
  color: rgba(159, 108, 239, 1) !important;
}
.Typewriter__wrapper {
  font-size: 67px !important;
}

@media (min-width: 601px) and (max-width: 1280px) {
  .cardTitle {
    font-size: 30px;
  }
  .cardSubTitle {
    font-size: 18px;
  }
  .pasddingFix16px {
    width: 410px;
    font-size: 22px;
  }
  .itWorksDivdesc {
    font-size: 18px !important;
  }
  #circle {
    height: 100px;
    width: 100px;
  }
  #circleName {
    font-size: 30px;
    margin-left: -43px;
  }
  .contactUs {
    font-size: 18px !important;
  }
  .help {
    font-size: 18px !important;
  }
  #animationDown {
    font-size: 18px;
  }
  .animation {
    font-size: 48px;
  }
  #contactUs {
    font-size: 36px !important;
    line-height: 43px !important;
    text-align: center;
  }
  #contactUsDesc {
    font-size: 18px !important;
    line-height: 27px !important;
    text-align: center;
  }
  .MuiGrid-grid-sm-12 {
    display: flex;
    justify-content: center;
  }

  #cardOne {
    width: 576px !important;
  }
  #cardTwo {
    width: 576px !important;
  }
}
.help {
  font-size: 20px;
  line-height: 24px;
}
.helpContactUs {
  font-size: 20px;
  line-height: 24px;
}
.arabic-class .landing-page-main-video-background{
  background: linear-gradient(270deg, #390689 3%, rgba(57, 6, 137, 0.5) 50%, rgba(57, 6, 137, 0) 100%);
}
@media (max-width: 601px) {
  #cardOne {
    width: 100%;
    min-height: 400px !important;
    margin: 0px !important;
  }
  #cardTwo {
    width: 100%;
    min-height: 400px !important;
    margin: 0px !important;
  }
  #cardOneDiv,#cardThreeBox {
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
  }
  #cardTwoBox {
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
  }
  .Typewriter__wrapper {
    font-size: 25px !important;
  }
}
@media (max-width: 500px) {
  #cardOneDiv {
    background-position: 9% 0% !important;
  }
  #cardTwoBox{
    background-position: 92% 0% !important;
  }
  #cardThreeBox{
    background-position: 25% 0% !important;
  }
}
@media (max-width: 1280px) {
  #cardOneDiv,#cardThreeBox {
    padding-left: 0px;
    padding-right: 0px;
  }
  .Typewriter__cursor {
    font-size: 50px !important;
  }
  .Typewriter__wrapper {
    font-size: 50px !important;
  }
}

@media (max-width: 900px) {
  .main_menu_login_button {
    display: none;
  }
}

@media (max-width:600px) {
  .mainDivLogin{
    height: 80px !important;
    padding: 0 16px !important;
  }
}

@media (max-width:1280px) {
  .mainDivLogin{
    padding: 0 16px !important;
  }
}

@media (min-width: 360px) and (max-width: 414px) {
  .help {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .helpContactUs {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  #contactUs {
    font-size: 30px !important;
    line-height: 38px !important;
  }
  #contactUsDesc {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .right-content {
    display: flex;
    justify-content: center;
    margin: 0px;
  }
  #animationDown {
    font-size: 20px;
  }
  .animation {
    font-size: 30px;
    line-height: 38.3px;
  }
  #wecoachTitle {
    font-size: 32px;
    line-height: 38.4px;
  }
  .animDownDescDiv {
    width: 358px;
  }
  .toolbar {
    width: 200px !important;
  }
  .logoImg {
    height: 20px !important;
    width: 91.81px !important;
  }
  .itWorksDivdesc {
    font-size: 18px !important;
  }
  .cardDesc {
    font-size: 18px !important;
    line-height: 25.2px !important;
  }
  .cardTitle {
    font-size: 24px !important;
    line-height: 28.8px !important;
  }
  .cardTitle:hover {
    cursor: pointer;
  }

  .cardContent {
    gap: 15px !important;
  }
  .cardBullets {
    gap: 5px !important;
  }
  #circleName {
    font-size: 20px;
    margin-left: -28px;
  }
  #headerTitileDesc {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: justify;
  }
  #cardOneDiv,#cardThreeBox{
    padding-left: 0px;
    padding-right: 0px;
  }
  .Typewriter__cursor {
    font-size: 42px !important;
  }
  .Typewriter__wrapper {
    font-size: 42px !important;
  }
}

@media(max-width:600px){
  #circleName {
    font-size: 24px;
    margin-left: -35px;
    word-spacing: -5px;
  }
  #circle{
    height:80px;
    width:80px
  }
  .logoImg {
    height: 20px !important;
    width: 91.81px !important;
  }
}
@media(max-width:500px){
  #circleName {
    font-size: 22px;
    margin-left: -31px;
    word-spacing: -8px;
  }
  #circle{
    height:70px;
    width:70px
  }
}

/* for indivisual butoon  */
.slide:hover,
.slide:focus {
  box-shadow: inset 13.5em 0 0 0 #24e09c;
  border-color: #24e09c;
  cursor: pointer;
}
.slide {
  color: #00bdff;
}

.slide button:hover,
.slide button:focus {
  border-color: #24e09c;
  color: #fff;
}
.forIndivisual button {
  height: 54px;
  width: 212px;
  border-radius: 32px;
  text-transform: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 16px;
  font-family: Open Sans, sans-serif !important;
  border: 3px solid;
  transition: 0.75s;
  background: none;
  line-height: 1;
  border-color: #24e09c;
}

/*  login button */

.login_slide {
  color: #FFFFFF;
}
.scrolled_login_slide:hover,
.scrolled_login_slide:focus {
  border: 3px solid #390689;
  color: #FFFFFF;
  cursor: pointer;
  transition: "all .3s ease-in-out",
}
.scrolled_login_slide:hover svg,
.scrolled_login_slide:focus svg,
.scrolled_login_slide {
  fill: #FFFFFF !important;
  transition: "all .3s ease-in-out",
}

.scrolled_login_slide {
  color: #390689;
}

.scrolled_login_slide svg {
  fill:#390689 !important;
  transition: "all .3s ease-in-out",
}
.scrolled_login_slide::before{
  background-color: #390689 !important;
  transition: "all .3s ease-in-out",
}
.scrolled_login_slide button:hover,
.scrolled_login_slide button:focus {
  border: 3px solid #390689;
  transition: "all .3s ease-in-out",
}
.loginButton button {
  height: 45px;
  width: 117px;
  border-radius: 32px;
  text-transform: none;
  font-weight: 700;
  font-size: 18px;
  gap: 10px;
  font-family: Open Sans, sans-serif !important;
  transition: "all .3s ease-in-out";
  background: none;
  line-height: 1;
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arabic-class .loginButton{
  justify-content: flex-start !important;
}

.arabic-class .landing-page-mobile-navigation{
  align-items:flex-start !important;
}

.login_purple{
  color: #390689;
  border: 3px solid #390689;
}
.login_purple svg{
  fill: #390689;
}
.login_white{
  color: #FFFFFF;
  border: 3px solid #FFFFFF;
}
.login_white svg{
  fill: #FFFFFF;
}
.hmbgr_login_purple{
  color: #390689;
  border: 3px solid #390689;
}
.hmbgr_login_purple svg{
  fill: #390689;
}

.hmbgr_login_white{
  color: #fff;
  border: 3px solid #fff;
}

.hmbgr_login_white svg{
  fill: #fff;
}

.landing-page-button{
  height: 45px;
  width: 117px;
  border-radius: 32px;
  text-transform: none;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 16px;
  font-family: "Open Sans", sans-serif !important;
  background: none;
  line-height: 24.51px;
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 300ms ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.arabic-class .landing-page-button{
  font-size: 16px;
  line-height: 17.51px !important;
  flex-direction: row-reverse;
}
.landing-page-button svg{
  height:18px;
  width:18px;
  min-height:18px;
  min-width:18px;
}
.landing-page-button::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0;
  z-index: -2;
}
.landing-page-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 0%;
  height: 100%;
  background-color: #fff;
  border-radius: 0;
  z-index: -1;
  transition: all .3s ease-in-out;
  mix-blend-mode: plus-lighter;
}
.landing-page-button:hover::before{
  width: 100%;
  transition: all .3s ease-in-out;
}
.landing-page-button:focus::before{
  width: 100%;
  transition: all .3s ease-in-out;
}
/* for business button */
.slideLeft {
  height: 54px;
  border-radius: 32px;
  width: 190px;
  font-weight: 700;
  color: #ffffff !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 20px;
  border: 3px solid #00bdff;
  background-color: transparent;
  background-image: #00bdff;
  background-image: -moz-linear-gradient(top, #00bdff 0%, #00bdff 100%);
  background-image: -webkit-linear-gradient(top, #00bdff 0%, #00bdff 100%);
  background-image: linear-gradient(to bottom, #00bdff 0%, #00bdff 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.slideLeft:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #00bdff;
  cursor: pointer;
}

/* Join us button */
.joinUs {
  white-space: nowrap;
  border-radius: 32px;
  font-weight: 700;
  text-transform: none as none;
  width: 126px;
  height: 45px;
  color: #ffffff !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 18px;
  border: 3px solid #f46c3d;
  background-color: transparent;
  background-image: #f46c3d;
  background-image: -moz-linear-gradient(top, #f46c3d 0%, #f46c3d 100%);
  background-image: -webkit-linear-gradient(top, #f46c3d 0%, #f46c3d 100%);
  background-image: linear-gradient(to bottom, #f46c3d 0%, #f46c3d 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.joinUs:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #f46c3d;
  cursor: pointer;
}

/* find coach button */
.findCoach {
  height: 45px;
  border-radius: 32px;
  width: 208px;
  color: #ffffff !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  border: 3px solid #24e09c;
  background-color: transparent;
  background-image: #24e09c;
  background-image: -moz-linear-gradient(top, #24e09c 0%, #24e09c 100%);
  background-image: -webkit-linear-gradient(top, #24e09c 0%, #24e09c 100%);
  background-image: linear-gradient(to bottom, #24e09c 0%, #24e09c 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.findCoach.arabic-class{
  font-size: 16px;
}
.findCoach:hover,.findCoach:focus {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #24e09c;
  cursor: pointer;
}

/* start now button */
#startNow {
  width: 155px;
  height: 45px;
  border-radius: 32px;
  color: #ffffff !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 24.51px;
  border: 3px solid rgba(0, 189, 255);
  background-color: transparent;
  background-image: rgba(0, 189, 255);
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 189, 255) 0%,
    rgba(0, 189, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 189, 255) 0%,
    rgba(0, 189, 255) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 189, 255) 0%,
    rgba(0, 189, 255) 100%
  );
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
#startNow:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid rgba(0, 189, 255, 0.4);
  cursor: pointer;
}

/* learn more1 */
.learnMoreOne {
  height: 45px;
  width: 168px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  border-radius: 32px;
  white-space: nowrap;
  font-family: Open Sans, sans-serif !important;
  color: #ffffff !important;
  border: 3px solid #00bdff;
  background-color: transparent;
  background-image: #00bdff;
  background-image: -moz-linear-gradient(top, #00bdff 0%, #00bdff 100%);
  background-image: -webkit-linear-gradient(top, #00bdff 0%, #00bdff 100%);
  background-image: linear-gradient(to bottom, #00bdff 0%, #00bdff 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.learnMoreOne:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #00bdff;
  cursor: pointer;
}

/* learnMoreTwo */
.learnMoreTwo {
  height: 45px;
  width: 168px;
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 30px;
  white-space: nowrap;
  font-family: Open Sans, sans-serif !important;
  color: #ffffff !important;
  border: 3px solid #24e09c;
  background-color: transparent;
  background-image: #24e09c;
  background-image: -moz-linear-gradient(top, #24e09c 0%, #24e09c 100%);
  background-image: -webkit-linear-gradient(top, #24e09c 0%, #24e09c 100%);
  background-image: linear-gradient(to bottom, #24e09c 0%, #24e09c 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.learnMoreTwo:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #24e09c;
  cursor: pointer;
}

/* learnMoreThree */
.learnMoreThree {
  height: 45px;
  width: 168px;
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 30px;
  white-space: nowrap;
  font-family: Open Sans, sans-serif !important;
  color: #ffffff !important;
  border: 3px solid #f46c3d;
  background-color: transparent;
  background-image: #f46c3d;
  background-image: -moz-linear-gradient(top, #f46c3d 0%, #f46c3d 100%);
  background-image: -webkit-linear-gradient(top, #f46c3d 0%, #f46c3d 100%);
  background-image: linear-gradient(to bottom, #f46c3d 0%, #f46c3d 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.learnMoreThree:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #f46c3d;
  cursor: pointer;
}

/* submit button */
.formSubmit {
  height: 45px;
  width: 105px;
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 30px;
  white-space: nowrap;
  font-family: Open Sans, sans-serif !important;
  color: #ffffff !important;
  border: 3px solid #390689;
  background-color: transparent;
  background-image: #390689;
  background-image: -moz-linear-gradient(top, #390689 0%, #390689 100%);
  background-image: -webkit-linear-gradient(top, #390689 0%, #390689 100%);
  background-image: linear-gradient(to bottom, #390689 0%, #390689 100%);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.formSubmit:hover {
  background-position: -300%;
  transition: background 300ms ease-in-out;
  border: 3px solid #390689;
  cursor: pointer;
}

.mainDivLogin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 93px;
  padding: 0px 48px;
  backdrop-filter: blur(50px);
}
.mainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 93px;
  padding: 0px 48px;
  backdrop-filter: blur(50px);
}
.forIndividuals {
  height: 54px;
  border-radius: 32px;
  width: 190px;
  font-weight: 700;
  font-family: Open Sans, sans-serif !important;
  font-size: 20px;
  border: 3px solid #24e09c;
  box-sizing: border-box;
  color: #fff;
  transition: all 300ms ease-in-out;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
}
.forIndividuals:focus{
  border: 3px solid #24e09c;
}
.forIndividuals::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 0;
  z-index: -2;
}

.forIndividuals::before {
  content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 0%;
    height: 100%;
    background-color: #24e09c;
    transition: all .3s ease-in-out;
    border-radius: 0;
    z-index: -1;
    mix-blend-mode: plus-lighter;
}

.forIndividuals:hover {
  border: 3px solid #24e09c;
}
.forIndividuals:hover::before{
  width: 100%;
}

.bg_purple {
  background-color: rgba(57, 6, 137, 0.5);
}

.bg_white {
  background-color: rgba(237, 239, 238, 0.85);
}

.landing-page-mobile-dropdown{
  position: absolute;
  width: 217px;
  right: -30px;
  top: 35px;
  padding: 10px;
  z-index: 1;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.arabic-class .landing-page-mobile-dropdown{
  left:-30px;
  right:unset;
}
.service_dropdown {
  position: absolute;
  left: 0;
  top: 90px;
  text-align: left;
  padding: 0 16px;
  display: none;
  width: 217px;
  backdrop-filter: blur(50px);
}
.arabic-class .service_dropdown{
  text-align: right;
  left:unset;
  right: 0;
}

.service_dropdown_bg_purple {
  background-color: rgba(57, 6, 137, 0.5);
}

.service_dropdown_bg_white {
  background-color: rgba(237, 239, 238, 0.85);
}

.service_dropdown ul {
  padding: 0;
}

.service_dropdown ul li {
  list-style: none;
  padding: 8px 0;
}

.service_dropdown_bg_purple ul li {
  color: rgba(238, 238, 238, 0.8);
}


.service_dropdown_scroll ul li {
  color: #390689;
}

.hover_dropdown:hover .service_dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hover_dropdown:hover .service_arrow {
  transform: rotate(180deg);
}
.hmbgr_serviceMenu{
  display: none;
}

.hmbgr_hover_dropdown:hover .hmbgr_serviceMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 7px;
}

.hmbgr_hover_dropdown:hover .service_arrow {
  transform: rotate(180deg);
}

.arabic-class .hmbgr_hover_dropdown{
  justify-content: flex-start !important;
}

.arabic-class .landing-page-our-service{
  font-size: 42px;
}
.arabic-class .landing-page-how-it{
  font-size: 50px;
}
@media(max-width:959.95px){
  .arabic-class .landing-page-our-service{
    font-size: 34px;
  }
  .arabic-class .landing-page-how-it{
    font-size: 38px;
  }
}

@media(max-width:599.95px){
  .arabic-class .landing-page-our-service{
    font-size: 26px;
  }
  .arabic-class .landing-page-how-it{
    font-size: 30px;
  }
}

.arabic-class  .landing-page-service-dropdown{
  flex-direction: row-reverse;
}

.arabic-class .landing-page-error-icon{
  margin-left: 3px;
  margin-right: unset !important;
}
